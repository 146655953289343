.topBar {
    position: static;
    width: 200%;
    height: 60px;
    left: 256px;
    top: 0px;
    margin-top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -15px;
    margin-bottom: 10px;
  }
  
  .title {
    position: relative;
    width: 150px;
    height: 33px;
    left: 3%;
    justify-content: center;
  
    font-weight: 600;
    font-size: 25px;
    line-height: 33px;
  
    color: #000000;
  }
  
  .topLinks a, .topLinks a:visited {
    margin: 10px;
    text-decoration: none;
    color: inherit;
  }
  
  .topLinks a:hover {
    font-weight: 600;
  }
  
  .topLinks {
    width: 30%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
  
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    color: #000000;
  }
  
  .help {
    position: relative;
    justify-content: center;
    flex-flow: 1 1 auto;
  }
  
  .support {
    position: relative;
    justify-content: center;
    flex-flow: 1 1 auto;
  }