.pickInfo {
    position: relative;
    width: 600px;
}

.fourth {
    width: 100px;
}

/* #continue {
    width: 125px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 6px 12px 0 rgba(87, 71, 81, 0.4);
  
    background: #EC3796;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 50px;
    text-align: center;
    color: white;
    border-width: 0px;
    cursor: pointer;
  }
  
  #continue:hover{
    background: #F354A8;
    border-width: 2px;
    border-color: gray;
  }
  
  #continue:active {
    border-width: 2px;
    border-color: white;
  } */