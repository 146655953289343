.submitAppForm {
    position: relative;
    left: 10%;
    width: 70%;
    height: 100%;
}

.submitInfo .horCont {
    justify-content: normal;
    align-items: flex-end;
}

.submitInfo input, .submitInfo select {
    width: 80%;
}

#small {
    width: 50%
}