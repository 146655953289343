.adjustInfo .vertCont {
    margin-right: 10px;
}

.applicationLabels {
    position: relative;
    left: 5%;
    width: 90%;
    justify-content: space-between;
}

.applicationList {
    position: relative;
    left: 5%;
    width: 90%;
}

.dz-button {
    height: 200px;
    width: 100%;
    text-align: center;
    border: 1px dashed skyblue
}