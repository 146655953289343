.pageBox {
    color: black;

    justify-content: center;
}

.numberBox {
    background-color: #faf9f8;
    cursor: pointer;
}

.arrowBox {
    background-color: #faf9f8;
    width: 40px;
    cursor: pointer;
}