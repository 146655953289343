.applicationBox {
    height: 50px;
    border-top-width: 1px;
    border-color: #dee2e6;
}
.applicationBoxChecked {
    height: 50px;
    background-color: rgb(124, 205, 255);

    border-width: 2px;
}

.applicationBox:hover {
    border: rgba(64, 104, 163, 0.5);
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
}

.applicationBoxChecked:hover {
    border: rgba(64, 104, 163, 0.5);
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
}

.applicationInfo {
    position: relative;
    left: 5%;

    width: 90%;
    margin: 0px;

    font-size: 12px;
}

.checkBox {
    position: relative;
    width: 20px;
    height: 20px;
    top: 0px;
}

.clientName {
    width: 150px;
}

.clientEmailAndPhone {
    width: 200px;
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
}

.planNameAndType {
    width: 250px;
}

.status {
    width: 150px;
}

.createDate {
    width: 100px;
}

@media (max-width: 1000px) {
    .applicationBox .clientEmailAndPhone, .applicationLabels .contactLabel{
        display: none;
    }
}

@media (max-width: 900px) {
    .applicationBox .planNameAndType, .applicationLabels .planLabel{
        display: none;
    }
}

@media (max-width: 750px) {
    .applicationBox .createDate, .applicationLabels .dateLabel{
        display: none;
    }
}
