.clientBox {
    height: 50px;
    border-top-width: 1px;
}

.clientBoxChecked {
    height: 50px;
    background-color: rgb(124, 205, 255);

    border-width: 2px;
}

.clientBox:hover {
    border: rgba(64, 104, 163, 0.5);
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
}

.clientInfo {
    position: relative;
    left: 5%;

    width: 90%;
    margin: 0px;

    font-size: 12px;
}

.clientName {
    width: 150px;
}

.clientEmailAndPhone {
    width: 200px;
}

.clientZip {
    width: 50px;
}

.clientDOB {
    width: 100px;
}

.createDate {
    width: 100px;
}

@media (max-width: 1000px) {
    .clientBox .clientDOB, .clientLabels .dobLabel{
        display: none;
    }
}

@media (max-width: 900px) {
    .clientBox .createDate, .clientLabels .dateLabel{
        display: none;
    }
}

@media (max-width: 600px) {
    .clientBox .clientEmailAndPhone, .clientLabels .contactLabel{
        display: none;
    }
}
