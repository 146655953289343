.adjustInfo .vertCont {
    margin-right: 10px;
}

.clientLabels {
    position: relative;
    left: 5%;
    width: 90%;
    justify-content: space-between;
}

.clientList {
    position: relative;
    left: 5%;
    width: 90%;
}
