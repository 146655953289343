.plusMinusButton {
  display: inline-block;
  text-decoration: none;
  width: 21px;
  height: 21px;
  color: #777777;
  border-radius: 50%;
  border: solid 2px #777777;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  transition: .4s;
  line-height: 17px;
  padding-left: 1px;
}

.plusMinusButton:hover {
    background: #b0b0b0;
}
