.bannerEmpty {
    height: 0px;
 }

.banner {
    width: 100%;
    height: 40px;
    top: -50px;

    display: flex;
    align-items: center;

    background: lightgreen;
    color: black;

    padding-left: 5%;
    font-family: Raleway;
    border-radius: 5px;
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

.banner-fadeout {
    position: absolute;
    width: 100%;
    height: 40px;
    top: -50px;

    display: flex;
    align-items: center;

    background: blue;
    color: black;

    padding-left: 5%;
    font-family: Raleway;
    border-radius: 5px;
    animation: fadeOut ease 2s;
    -webkit-animation: fadeOut ease 2s;
    -moz-animation: fadeOut ease 2s;
    -o-animation: fadeOut ease 2s;
    -ms-animation: fadeOut ease 2s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


@keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
  }

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
