.loginBackground {
    background: #e5e5e5;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    width: 400px;
    height: 500px;
    background-color: #faf9f8;
    border-radius: 15px;
    box-shadow: 0 4px 50px rgba(0,0,0,.05);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.loginContainer p {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
}

#signUp {
    background: white;
    color: black;
    box-shadow: none;
    border: 2px solid black;
}