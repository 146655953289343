.menu {
  width: auto;
  height: 100vh;

  display: flex;
  align-items: center;
}

#sidebar {
    position: fixed;
    height: 100%;
    width: 256px;
    background: white;
    flex: 0 0 auto;
    display: flex;
    z-index: 1;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .profileInfo {
    position: relative;
    width: 256px;
    height: 105px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .profilePic {
    position: relative;
    width: 50px;
    height: 50px;
    align-self: center;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: #FFFFFF;
  }

  .profileName {
    position: relative;
    width: 175px;
    height: 36px;
    margin-top: 10px;

    font-family: Raleway;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    word-wrap: normal;

    color: black;
  }

  .pageDirect {
    position: relative;
    width: 180px;
    height: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-self: center;

    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
  }

  .directCon {
    width: 150px;
    height: 40px;
    margin: 0px;
    border-radius: 15px 0px 0px 15px;
    display: flex;
    flex-direction: row;

    align-items: center;
    text-indent: 15px;
    color: #334D6E;
  }

  .sidebar #logo {
    display: flex;
    justify-content: center;
  }

  .directCon:hover {
    background-color: #FAF9F8;
    color: black;
  }

  .openArrow {
    position: fixed;
    height: 150px;
    width: 40px;

    background-color: grey;
    color: white;

    border: none;
    border-radius: 0px 25px 25px 0px;
    z-index: 0;

  }
