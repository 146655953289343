.appInfo {
    position: relative;
    left: 10%;
    margin-top: 10px;
    width: 50%;

}

.appInfo label {
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
}

.appInfo a:hover {
    font-weight: 500;
}
