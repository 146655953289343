* {
    box-sizing: border-box;
    margin: 0;
    padding:0;
  }
  html, body {
    width: 100%;
    min-height: 100vh;
    background: #faf9f8;
    overflow-x: hidden;
    font-family: Raleway;
  }

  select, input[type=text], input[type=email], input[type=date] {
    background: #F0F0F0;
    height: 35px;
    border-radius: 25px;
    border-width: 0px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-indent: 10px;
    margin-top: 5px;
    margin-right: 5px;
  }
  
  input[type=text], input[type=email] {
    width: 100%;
  }

  #root {
    width: 100%;
    height: inherit;
  }
  .master {
    margin: 0;
    padding: 0;
  }

  .container {
     display: flex;
     flex-direction: column;
  }

  .background {
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    background: #FAF9F8;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: stretch;
  }

  .middle {
    position: static;
    width: auto;
    background: #e5e5e5;
    flex: 1 1 auto;
    margin-left: 256px;
    max-width: 1800px;
    width: 70%;
  }

  .mainTitle {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    color: #000000;
  }

  .titleInfo {
    position: relative;
    left: 5%;
    width: 95%;
  }

  .titleName {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
  }

  .titleDetails {
    font-family: Raleway;
    font-style: normal;
    font-size: 15px;

    margin-top: 10px;
  }

  .mainArea {
    position: relative;
    height: auto;
    margin-left: 2%;
    width: 96%;
    background-color: white;
    border-radius: 5px;
  }

  .horCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 10px;
}

.vertCont {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    width: 100%;
}

.linkStyle {
  margin: 0px;
  font-size: 15px;
  text-decoration: underline;
  color: #4068A3;
}

.linkStyle:hover {
  color: #EC3796;
}

.linkStyle:active {
  color: blue;
}

.buttonStyle {
  width: 125px;
  height: 50px;
  border-radius: 10px;

  background: #109CF1;
  transition:.3s;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: white;
  border-width: 0px;
  cursor: pointer;
}

.secondaryButtonStyle {
  width: 125px;
  height: 50px;
  border-radius: 10px;
  border-style: solid;

  background: white;
  transition:.3s;
  box-shadow: 0 6px 12px 0 rgba(87, 71, 81, 0.4);

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #109cf1;
  border-color: #109cf1;
  border-width: 2px;
  cursor: pointer;
}

.buttonStyle:hover{
  background: #0c82c9;
}

.disabledButtonStyle {
  width: 125px;
  height: 50px;
  border-radius: 25px;

  background: lightgrey;
  box-shadow: 0 6px 12px 0 rgba(87, 71, 81, 0.4);

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 50px;
  text-align: center;
  color: white;
  border-width: 0px;
  cursor: not-allowed;
}

button:focus {
  outline: none;
}

button::-moz-focus-inner { border: 0; }

table, th, td {
  border: none;
  border-collapse: collapse;
  padding: 5px;
}

.table-cell {
  font-size: 16px;
  white-space: nowrap;
  overflow: scroll;
  font-weight: normal;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.table-cell::-webkit-scrollbar {
  display: none;
}

.dropdown-toggle::after {
  display: none; 
}

.btn-primary {
  color: #fff;
  background-color: #109cf1;
  border-color: #109cf1;
}
#override-bootstrap .dz-progress {
  height: 5px;
}
#override-bootstrap .dropzone {
  border: 1px dashed #87ceeb;
  background-color:rgb(198 198 198);
}
#override-bootstrap .dz-error-mark {
  display: none;
}
.annotationLayer {
  height: 0px !important;
}