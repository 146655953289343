.dashArea {
    height: 90%;
    width: 90%;
    margin-top: 35px;
    margin-left: 40px;

    display: flex;
    flex-direction: row;
}

.appArea {
    width: 60%;
    height: 100%;

    margin-right: 30px;

    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.graphArea {
    width: 35%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

#dashGraph1 {
    width: 100%;

    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

#dashGraph2 {
    width: 100%;
    flex: auto;

    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.dashBox {
    width: 95%;
    align-self: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    line-height: 0.2em;
    padding-top: 20px;
    height: 90px;
}

.dashStatus {
    margin-top: 5px;
    color: #707683;
}

.dashBox:hover {
    border: rgba(64, 104, 163, 0.5);
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
}

.incompleteApps {
    /* font-family: Poppins;
    color: #334D6E; */

    margin-left: 10px;
    margin-top: 10px;
}

@media (max-width: 1000px) {
    .graphArea {
        display: none;
    }
    .appArea {
        margin-right: 0;
        width: 100%;
    }
}