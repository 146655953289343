

.submitButton, .whiteButton {
    padding: 5px; 
    padding-left: 10px; 
    padding-right: 10px; 
    font-size: 1em; 
    background-color: white; 
    font-family: 'DM Sans'; 
    border-radius: 35px; 
    display: block; 
    border-color: black;
    border-style: solid;
    width: 150px;
    color: black;
}
.whiteButton {
    width: auto;
    margin-bottom: 5px;
}

.submitButton:hover, .whiteButton:hover {
    background-color: #9fd4ff;
}

h3 {
    font-family: 'DM Sans';
    font-size: 1.5em;
}
.col1 {
    width: 30%;
    float: left;
    margin-right: 10px;
}
.col2 {
    width: 30%;
    float: left;
    margin-right: 10px;
}
.col3 {
    width: 30%;
    float: left;
    margin-right: 10px;
}

.breadcrumbs-bootstrap {
    --bs-breadcrumb-divider: ">";
}
.breadcrumb {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;
}

@media (max-width: 1024px) {
    .headbar {
        color: white;
        width: 130%;
        height:90px;
        margin-left: -30%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5%;
        background-color: black;
    }
    .headbar-links {
        display: none;
    }
    .logo-desktop {
        display: none;
    }
    .hamburger-menu {
        display: flex;
    }
    .hamburger-menu-links {
        display: flex;
    }
    .hamburger-bg {
        display: block;
    }
    .bodyParagraph {
        width: 90%;
        margin-bottom: 50px;
    }
    .desktop-only-cell {
        display: none;
    }
    .mobile-margin {
        margin-left: 10px;
    }
    .horiz-scroll-mobile {
        overflow-x: scroll;
    }
}
@media (min-width: 1025px) {
    .headbar {
        color: black;
        width: 130%;
        height:115px;
        margin-left: -30%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5%;
        background-color: white;
    }
    .headbar-links {
        display: block;
    }
    .logo-desktop {
        display: block;
    }
    .hamburger-menu {
        display: none;
    }
    .hamburger-links {
        display: none;
    }
    .hamburger-bg {
        display: none;
    }
    .bodyParagraph {
        min-width: 60%;
        width: 300px;
        margin-bottom: 50px;
    }
    .desktop-only-cell {
        display: table-cell;;
    }
    .mobile-margin {
        margin-left: 15%;
    }
    .horiz-scroll-mobile {
        overflow-x: none;
    }
}