.clientSelectForm {
    position: relative;
    left: 5%;
    width: 90%;
}

.clientBox {
    height: 50px;
    border-top-width: 1px;
    border-color: #dee2e6;
}

.clientBoxChecked {
    height: 50px;
    background-color: rgb(124, 205, 255);

    border-width: 2px;
}

.searchSelect {
    margin: 20px 0px 20px 0px;
    width: 250px;
}