form.adjustAppForm {
    width: 90%;
}

.adjustInfo .vertCont {
    margin-right: 10px;
}


#radioPlan {
    height: 20px;
    width: 20px;
    margin: 0px 20px;
}

.boxLabels {
    position: relative;
    left: 5%;
    width: 90%;
    justify-content: space-between;
    margin-left: -10px;
}

.planSelectForm {
    position: relative;
    left: 5%;
    width: 90%;
}

@media (max-width: 1000px) {
    .adjustGender {
        display: none;
    }
}

@media (max-width: 950px) {
    .adjustTobacco {
        display: none;
    }
}

@media (max-width: 900px) {
    .adjustAge {
        display: none;
    }
}
