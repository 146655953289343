.planBox {
    justify-content: initial;
    align-items: center;
    height: 80px;
    width: 100%;
    margin-top: 10px;

    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}

.otherPlanBox {
    justify-content: initial;
    align-items: center;
    height: 80px;
    width: 100%;
    margin-top: 10px;
    margin-left: 120px;
}

.subPlans {
  flex-direction: column-reverse;
  width:100%;
}

.subPlanBox {
    justify-content: initial;
    height: 80px;
    width: 100%;
    margin-top: 10px;
    margin-left: 150px;
    float: left;
    align-items: flex-end;
}

.planInfo {
    width: 90%;
    margin: 0px;
}

.planInfo label {
    color: #4068A3;
}

.companyName {
    display:flex;
    width: 450px;
    height: 50px;

    align-items: center;
}

.premium {
    width: 100px;
    height: 20px;
}

.notes {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
}