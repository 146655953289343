body {
  margin: 0;
  padding: 0;
  font-family: Raleway, sans-serif;
  position: absolute;
  width: 100%;
  min-height: 100%;
}

h1 {
  font-family: sans-serif;
}

.master {
  position: absolute;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}

input:focus {
  outline: none;
}

.outerDiv {
  position: relative;
  display: flex;
  width: 100%;
  pointer-events: none;
}

.innerDiv {
  position: relative;
  box-sizing: border-box;
  pointer-events: auto;
  width: 300px;
}

.centerer {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.maxer {
  align-items: flex-end;
  position: absolute;
  bottom: 0;
}

.vector svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

a {
  cursor: pointer;
  text-decoration: none;
}
