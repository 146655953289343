.clientForm {
  max-width: 900px;
  padding: 50px;
  padding-top: 10px;
}

label {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}

.hide {
  display: none;
}

select:focus {
  outline: 0px;
}

.info {
  position: relative;
}

#title {
  width: 80px;
  border-radius: 20px;
  color: black;
}

.fname {
  width: 100%;
  margin-right: 10px;
}

.mi {
  min-width: 75px;
  max-width: 75px;
}

.half {
  width: 45%;
  margin: 0px;
}

.end {
  position: relative;
  left: 55%;
}

.continue {
  margin: 20px 0px 40px 0px;
  justify-content: start;
}

.bottom {
  margin-top: 10px;
}