.formArea {
  position: relative;
  left: 10%;
  right: 10%;
  width: 80%;
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: 0 4px 50px rgba(0,0,0,.05);
}

.radioArea {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fieldArea {
  margin-left: 5%;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 70%;
}

input:-moz-read-only { /* For Firefox */
  font-weight: 600;
}

input:read-only {
  font-weight: 600;
}
