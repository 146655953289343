.modal {
  position: fixed;
  z-index: 1;
  background-color: rgba(181, 181, 181, 0.41);
  width: 100%;
  height: 200%;
  margin-top: -200px;
  margin-left: -200px;
  display: block;
  left: 200px;
}

.modalContent {
  border-radius: 20px;
  background-color: #faf9f8;
  position: absolute;
}


form.adjustAppForm {
    width: 90%;
}

.adjustInfo .vertCont {
    margin-right: 10px;
}


#radioPlan {
    height: 20px;
    width: 20px;
    margin: 0px 20px;
}

.boxLabels {
    position: relative;
    left: 5%;
    width: 90%;
    justify-content: space-between;
    margin-left: -10px;
}

.planSelectForm {
    position: relative;
    left: 5%;
    width: 90%;
}

@media (max-width: 1000px) {
    .adjustGender {
        display: none;
    }
}

@media (max-width: 950px) {
    .adjustTobacco {
        display: none;
    }
}

@media (max-width: 900px) {
    .adjustAge {
        display: none;
    }
}
